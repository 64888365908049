
body {
	font-size: 14px;
	font-family: -apple-system, "Helvetica Neue", "Roboto", sans-serif;
	line-height: 1.2;
}

.btn {
	font-size: 14px;
}

.form-save-buttons {
	text-align: center;
	padding-bottom: 6px;
}


	.form-save-buttons input[type=submit],
	.form-save-buttons button {
		min-width: 90px;
	}

		.form-save-buttons button:first-child {
			margin-right: 10px;
		}

.fa-plus {
	color: green;
	position: relative !important;
	top: -1px !important;
	font-size: 12px !important;
}

#slick-toasts {
	right: 5px !important;
	top: 48px !important;
}

.fa-thumbtack {
	transform: rotate(-40deg);
}

label {
	margin-bottom: 0;
}

.form-group {
	margin-bottom: .75rem;
}

.pac-container {
	z-index: 50000 !important;
}

.save-button,
.cancel-button {
	width: 80px;
}

.save-button {
	margin-right: 10px;
}

	.save-button .fa-check-circle,
	.save-button .fa-save,
	.cancel-button .fa-ban {
		font-size: 13px;
		position: relative;
		top: -1px;
	}

	.save-button .fa-check-circle {
	}


.profile-picture {
	height: 40px;
	width: 40px;
	border-radius: 30px;
}

pre {
	white-space: pre-wrap;
	word-break: keep-all;
	overflow: hidden;
	line-height: 16px;
	font-family: Roboto;
}

.viewer-button.viewer-close {
	top: 40px;
	right: 20px;
	height: 40px;
	width: 40px;
	text-align: center !important;
	line-height: 30px;
}

.viewer-button.viewer-close:before {
	height: 15px;
	margin-left: -4px;
}